import React from 'react'
import { Link } from "react-router-dom";
// Discover Data
import { DiscoverData } from './DiscoverData'
// Discover Card
import DiscoverCard from './DiscoverCard'
import SectionHeading from '../../Common/SectionHeading'

const AboutUs = () => {
    return (
        <>
            <section id="go_beyond_area" className="section_padding_top">
                <div className="container">
                    <SectionHeading heading="About Us" />
                    {/* <br></br> */}
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area">
                                <h2><span>Our Mission</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area">
                                <h4>At Safar On Time, our mission is to empower travelers with the knowledge they need to make informed decisions about their flights. We believe that providing transparent flight performance data can lead to better travel experiences and help travelers plan with confidence.</h4>
                            </div>
                        </div>
                        {/* {DiscoverData.map((data, index) => (
                     <DiscoverCard data={data} key={index} /> 
                ))} */}

                    </div>
                    <br></br>
                    <br></br>
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area">
                                <h2><span>Our Story</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area">
                                <h4>The idea for Safar On Time was born out of personal experiences with frequent flight delays. After numerous instances of choosing flights that ended up being delayed more than others, we realized the need for a tool that provides reliable, historical flight performance data. With access to this data, travelers can choose the best flights, be better prepared, and enjoy smoother journeys.</h4>
                            </div>
                        </div>
                        {/* {DiscoverData.map((data, index) => (
                     <DiscoverCard data={data} key={index} /> 
                ))} */}

                    </div>
                    <br></br>
                    <br></br>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="section_heading_center">
                                <div className="home_news_big">
                                    <div className="news_home_bigest img_hover custom_read_more">
                                        <Link to="/about">Read more <i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default AboutUs