import React, { useState } from "react";

const ContactArea = () => {
  const [ firstName, setFirstName] = useState('');
  const [ lastName, setLastName] = useState('');
  const [ email, setEmail] = useState('');
  const [ mobile, setMobile] = useState(null);
  const [ msg, setMsg] = useState('');

  const [ firstNameError, setFirstNameError] = useState(false);
  const [ lastNameError, setLastNameError] = useState(false);
  const [ emailError, setEmailError] = useState(false);


  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setMobile('');
    setMsg('');
  };

  const validateEmail = (email) => {
    if(email.trim()==""){
      return true
    }
    // Basic email regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = () => {
    
    if(firstName.trim()==""){
      setFirstNameError(true);
      return
    } else {
      setFirstNameError(false);
    }

    if(lastName.trim()==""){
      setLastNameError(true);
      return
    } else {
      setLastNameError(false);
    }

    if(!validateEmail(email)){
      setEmailError(true);
      return
    } else {
      setEmailError(false);
    }

    const contactForm = {
      firstName,
      lastName,
      email,
      mobile,
      msg
    };
  
    // Send the POST request and do not wait for the response
    fetch('https://safarontime-springbootapp-new.an.r.appspot.com/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contactForm),
    }).catch(error => {
      console.error('Error:', error);
      // Handle the error if needed
    });

    alert("Thank you for your feedback. It is immensely valuable to us!")

    resetForm()
  }

  return (
    <>
      <section id="contact_main_arae" className="section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section_heading_center">
                <h2>Get In Touch</h2>
              </div>
            </div>
          </div>
          <div className="contact_main_form_area_two">
            <div className="row">
              <div className="col-lg-8">
                <div className="contact_left_top_heading">
                  <h2>
                    Do you have any feedback, suggestions or ideas?
                  </h2>
                  {/* <h3>We’re always looking for feedback and suggestions to improve our platform. If you have any ideas or just want to say hi, feel free to reach out. We’re excited to hear from you!</h3> */}
                  <p>
                    We’re always looking for feedback and suggestions to improve our platform. If you have any ideas or just want to say hi, feel free to reach out. We’re excited to hear from you!
                  </p>
                </div>
                <div className="contact_form_two">
                  <form action="!#" id="contact_form_content">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className={firstNameError ? "form-control bg_input error" : "form-control bg_input"}
                            // className="form-control bg_input"
                            placeholder="First name*"
                            value={firstName}
                            onChange={(event)=>{
                              setFirstName(event.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className={lastNameError ? "form-control bg_input error" : "form-control bg_input"}
                            // className="form-control bg_input"
                            placeholder="Last name*"
                            value={lastName}
                            onChange={(event)=>{
                              setLastName(event.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className={emailError ? "form-control bg_input error" : "form-control bg_input"}
                            // className="form-control bg_input"
                            placeholder="Email address (Optional)"
                            value={email}
                            onChange={(event)=>{
                              setEmail(event.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control bg_input"
                            placeholder="Mobile number (Optional)"
                            value={mobile}
                            onChange={(event)=>{
                              setMobile(event.target.value)
                            }}
                            maxLength={10}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea
                            className="form-control bg_input"
                            rows="5"
                            placeholder="Message"
                            value={msg}
                            onChange={(event)=>{
                              setMsg(event.target.value)
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <button type="button" className="btn btn_theme btn_md" onClick={handleSubmit}>
                            Send message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contact_two_left_wrapper">
                  <h3>Contact details</h3>
                  <div className="contact_details_wrapper">
                    {/* <div className="contact_detais_item">
                      <h4>Help line</h4>
                      <h3>
                        <a href="tel:+01-234-567-890">+01 234 567 890</a>
                      </h3>
                    </div> */}
                    <div className="contact_detais_item">
                      <h4>Email</h4>
                      <h4>
                        <a href="mailto:contact@safarontime.com">
                          contact@safarontime.com
                        </a>
                      </h4>
                    </div>
                    <div className="contact_detais_item">
                      <h4>Follow us on</h4>
                      <ul className="social-media-icons">
                        {/* <a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a> */}
                        <a href="https://x.com/SafarOnTime"><i className="fab fa-twitter-square"></i></a>
                        <a href="https://www.instagram.com/safar.on.time/"><i className="fab fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/safar-on-time/"><i className="fab fa-linkedin"></i></a>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="contact_map_area">
                      <iframe
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"
                      ></iframe>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default ContactArea;
