import img1 from '../../assets/img/tour-guides/guide-1.png'
import img2 from '../../assets/img/tour-guides/guide-2.png'
import img3 from '../../assets/img/tour-guides/guide-3.png'
import img4 from '../../assets/img/tour-guides/guide-4.png'
import img5 from '../../assets/img/tour-guides/guide-5.png'
import img6 from '../../assets/img/tour-guides/guide-6.png'
import img7 from '../../assets/img/tour-guides/guide-7.png'
import img8 from '../../assets/img/tour-guides/guide-8.png'





export const GuideData = [
    {
        img: img1,
        name:"Jesica simpsn", 
        des:"Tourist",
    },
    {
        img: img2,
        name:"Jesica simpsn", 
        des:"Tourist",
    },
    {
        img: img3,
        name:"Jesica simpsn", 
        des:"Tourist",
    },
    {
        img: img4,
        name:"Jesica simpsn", 
        des:"Tourist",
    },
    {
        img: img5,
        name:"Jesica simpsn", 
        des:"Tourist",
    },
    {
        img: img6,
        name:"Jesica simpsn", 
        des:"Tourist",
    },
    {
        img: img7,
        name:"Jesica simpsn", 
        des:"Tourist",
    },
    {
        img: img8,
        name:"Jesica simpsn", 
        des:"Tourist",
    }
   
]