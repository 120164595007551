// OnTimePerformanceChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the data labels plugin

// Register Chart.js components and the data labels plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Register the data labels plugin
);

const OnTimePerformanceChart = ({ chartData, x_limit }) => {
  const data = {
    // labels: ['On time (<15 min)   ', 'Late (<30 min)         ', 'Very late (<45 min)   ', 'Excessive (>45 min) ', 'Cancelled                 '],
    labels: ['On time (<15 min)', 'Late (<30 min)', 'Very late (<45 min)', 'Excessive (>45 min)', 'Cancelled'],
  
    datasets: [
      {
        label: 'Number of Flights',
        data: [chartData.onTime, chartData.late, chartData.veryLate, chartData.excessive, chartData.cancelled],
        backgroundColor: [
          "#63c8f3",
          "#63c8f3",
          "#63c8f3",
          "#63c8f3",
          "#63c8f3",
        ],
        borderColor: [
          "#009cdf",
          "#009cdf",
          "#009cdf",
          "#009cdf",
          "#009cdf",
        ],
        borderWidth: 1,
        barThickness: window.innerWidth < 900 ? 20 : 30,
        categoryPercentage: 0,
        barPercentage: 0.9,
      },
    ],
  };

  // const options = {
  //   indexAxis: 'y',
  //   plugins: {
  //     datalabels: {
  //       color: '#444', // Color of the data labels
  //       anchor: 'end', // Position of the label (end is on top)
  //       align: 'end', // Align the label with the end of the bar
  //       formatter: (value) => value, // Format the label text (here just the value itself)
  //       offset: 5, // Space between the bar and the label
  //       font: {
  //         weight: 'normal', // Font weight of the label
  //       },
  //     },
  //     tooltip: {
  //       enabled: false, // Disable tooltips
  //     },
  //   },
  //   scales: {
  //     x: {
  //       max: x_limit,
  //       beginAtZero: true,
  //       grid: {
  //         color: 'rgba(0, 0, 0, 0.1)',
  //         borderDash: [5, 5],
  //         lineWidth: 1,
  //       },
  //     },
  //   },
  // };

  const options = {
    indexAxis: 'y',
    plugins: {
      datalabels: {
        color: '#444',
        anchor: 'end',
        align: 'end',
        formatter: (value) => value,
        offset: 5,
        font: {
          weight: 'normal',
          size: window.innerWidth < 900 ? 10 : 14, // Adjust font size based on screen width
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        max: x_limit,
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
          borderDash: [5, 5],
          lineWidth: 1,
        },
      },
      y: {
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 0,
          padding: 0,
          font: {
            size: window.innerWidth < 900 ? 12 : 12, // Adjust tick font size
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Allows setting the aspect ratio manually
    aspectRatio: 2, // Set the aspect ratio to 2 (width:height), adjust as needed
  };
  
  

  return (
    <div className="chart-container">
      <Bar data={data} options={options} />
    </div>
  );
};

export default OnTimePerformanceChart;
