// DelayStatistics.js
import React from 'react';

const DelayStatistics = ({ stats }) => {

  // const statistics = {
  //   totalFlights: 21,
  //   totalDelays: 4,
  //   averageDelay: 14,
  //   standardDeviation: 7.1,
  //   minDelay: 0,
  //   maxDelay: 23,
  // };


  return (
    // <div className="statistics-container">
    //   <h2 className="statistics-heading">Delay Statistics</h2>
    //   <table className="statistics-table">
    //     <tbody>
    //       <tr>
    //         <th>Total Flights Observed</th>
    //         <td>{statistics.totalFlights}</td>
    //       </tr>
    //       <tr>
    //         <th>Total Delays (&gt; 1 min)</th>
    //         <td>{statistics.totalDelays}</td>
    //       </tr>
    //       <tr>
    //         <th>Average Delay (min.)</th>
    //         <td>{statistics.averageDelay}</td>
    //       </tr>
    //       <tr>
    //         <th>Standard Deviation</th>
    //         <td>{statistics.standardDeviation}</td>
    //       </tr>
    //       <tr>
    //         <th>Min</th>
    //         <td>{statistics.minDelay}</td>
    //       </tr>
    //       <tr>
    //         <th>Max</th>
    //         <td>{statistics.maxDelay}</td>
    //       </tr>
    //     </tbody>
    //   </table>
    // </div>
    // ----------
    <div>
          <div className="tour_detail_right_sidebar_borderless">
            <div className="tour_details_right_boxed">
              <div className="tour_details_right_box_heading">
                <h3>Delay Statistics</h3>
              </div>
              <div className="tour_package_details_bar_list">
                <div className="select_person_item">
                  <div className="select_person_left">
                    <h6>Total Flights Observed</h6>
                  </div>
                  <div className="select_person_right">
                    {/* <h6>{statistics}</h6> */}
                    <h6>{stats.totalFlights}</h6>
                  </div>
                </div>
                <div className="select_person_item">
                  <div className="select_person_left">
                    <h6>Total Delays (&gt; 1 min)</h6>
                  </div>
                  <div className="select_person_right">
                    {/* <h6>{statistics.totalDelays}</h6> */}
                    <h6>{stats.totalDelays}</h6>
                  </div>
                </div>
                <div className="select_person_item">
                  <div className="select_person_left">
                    <h6>Average Delay (min)</h6>
                  </div>
                  <div className="select_person_right">
                    <h6>{stats.averageDelay}</h6>
                  </div>
                </div>
                <div className="select_person_item">
                  <div className="select_person_left">
                    <h6>Standard Deviation</h6>
                  </div>
                  <div className="select_person_right">
                    <h6>{stats.standardDeviation}</h6>
                  </div>
                </div>
                <div className="select_person_item">
                  <div className="select_person_left">
                    <h6>Min</h6>
                  </div>
                  <div className="select_person_right">
                    <h6>{stats.minDelay}</h6>
                  </div>
                </div>
                <div className="select_person_item">
                  <div className="select_person_left">
                    <h6>Max</h6>
                  </div>
                  <div className="select_person_right">
                    <h6>{stats.maxDelay}</h6>
                  </div>
                </div>

              </div>
            </div>
          </div>
    </div>
  );
};

export default DelayStatistics;


{/* <div className="select_person_item">
                <div className="select_person_left">
                  <h6>Average Delay (min)</h6>
                </div>
                <div className="select_person_right">
                  <h6>{statistics.averageDelay}</h6>
                </div>
              </div>
              <div className="select_person_item">
                <div className="select_person_left">
                  <h6>Standard Deviation</h6>
                </div>
                <div className="select_person_right">
                  <h6>{statistics.standardDeviation}</h6>
                </div>
              </div>
              <div className="select_person_item">
                <div className="select_person_left">
                  <h6>Min</h6>
                </div>
                <div className="select_person_right">
                  <h6>{statistics.minDelay}</h6>
                </div>
              </div>
              <div className="select_person_item">
                <div className="select_person_left">
                  <h6>Max</h6>
                </div>
                <div className="select_person_right">
                  <h6>{statistics.maxDelay}</h6>
                </div>
              </div> */}