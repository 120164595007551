import React from "react";
// import SortCriterion from "./index";

const SortCriterion = Object.freeze({
    PERFORMANCE: 'Performance',
    EARLY_DEPARTURE: 'Early Departure',
    LATE_DEPARTURE: 'Late Departure',
});

const FlightSort = (props) => {

    const { setSortCriterion, sortCriterion, avgOtpRoute, avgDelayRoute, dataDateRange } = props

    return (
        <>
        <div class="desktop-only">
            <section id="destinations_area" className="section_padding_top_half">
                <div className="container">
                    <div className="row">
                        {/* <div className="theme_nav_tab_info_mobile"> */}
                            <div className="col-lg-3">
                                <div className="theme_nav_tab_info">
                                    <nav className="theme_nav_tab_item">
                                        <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                                            <h5>Data Time Period :</h5>
                                            <h5>{dataDateRange ? dataDateRange : "No Data Available for this route"}</h5>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            {/* <div className="col-lg-6 offset-lg-3"> */}
                            <div className="col-lg-6">
                                <div className="theme_nav_tab">
                                    <nav className="theme_nav_tab_item">
                                        <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                                            <button
                                                className={sortCriterion == SortCriterion.PERFORMANCE ? "nav-link active" : "nav-link"}
                                                id="nav-hotels-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-hotels"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-hotels"
                                                aria-selected="true"
                                                onClick={() => {
                                                    setSortCriterion(SortCriterion.PERFORMANCE)
                                                }}
                                            >
                                                Best Performance
                                            </button>
                                            <button
                                                className={sortCriterion == SortCriterion.EARLY_DEPARTURE ? "nav-link active" : "nav-link"}
                                                id="nav-tours-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-tours"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-tours"
                                                aria-selected="false"
                                                onClick={() => {
                                                    setSortCriterion(SortCriterion.EARLY_DEPARTURE)
                                                }}
                                            >
                                                Early Departure
                                            </button>
                                            <button
                                                className={sortCriterion == SortCriterion.LATE_DEPARTURE ? "nav-link active" : "nav-link"}
                                                id="nav-space-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-space"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-space"
                                                aria-selected="false"
                                                onClick={() => {
                                                    setSortCriterion(SortCriterion.LATE_DEPARTURE)
                                                }}
                                            >
                                                Late Departure
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="theme_nav_tab_info">
                                    <nav className="theme_nav_tab_item">
                                        <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                                            <h5>Avg Route OTP - {avgOtpRoute ? avgOtpRoute?.toString().substring(0,5) + "%" : "#NA"} </h5>
                                            <h5>Avg Route Delay - {avgDelayRoute ? avgDelayRoute?.toString().substring(0,5) + " min" : "#NA"}</h5>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </section >
            </div>
            <div class="mobile-only">
            <section id="destinations_area" className="section_padding_top_half">
                <div className="container">
                    <div className="row">
                        {/* <div className="theme_nav_tab_info_mobile"> */}
                            <div className="col-sm-6">
                                <div className="theme_nav_tab_info">
                                    <nav className="theme_nav_tab_item">
                                        <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                                            <h5>Data Time Period :</h5>
                                            <h5>{dataDateRange ? dataDateRange : "No Data Available for this route"}</h5>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="theme_nav_tab_info">
                                    <nav className="theme_nav_tab_item">
                                        <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                                        <h5>Avg Route OTP - {avgOtpRoute ? avgOtpRoute?.toString().substring(0,5) + "%" : "#NA"} </h5>
                                            <h5>Avg Route Delay - {avgDelayRoute ? avgDelayRoute?.toString().substring(0,5) + " min" : "#NA"}</h5>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            {/* <div className="col-lg-6 offset-lg-3"> */}
                            <div className="col-sm-12">
                                <div className="theme_nav_tab">
                                    <nav className="theme_nav_tab_item">
                                        <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                                            <button
                                                className={sortCriterion == SortCriterion.PERFORMANCE ? "nav-link active" : "nav-link"}
                                                id="nav-hotels-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-hotels"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-hotels"
                                                aria-selected="true"
                                                onClick={() => {
                                                    setSortCriterion(SortCriterion.PERFORMANCE)
                                                }}
                                            >
                                                Best Performance
                                            </button>
                                            <button
                                                className={sortCriterion == SortCriterion.EARLY_DEPARTURE ? "nav-link active" : "nav-link"}
                                                id="nav-tours-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-tours"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-tours"
                                                aria-selected="false"
                                                onClick={() => {
                                                    setSortCriterion(SortCriterion.EARLY_DEPARTURE)
                                                }}
                                            >
                                                Early Departure
                                            </button>
                                            <button
                                                className={sortCriterion == SortCriterion.LATE_DEPARTURE ? "nav-link active" : "nav-link"}
                                                id="nav-space-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-space"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-space"
                                                aria-selected="false"
                                                onClick={() => {
                                                    setSortCriterion(SortCriterion.LATE_DEPARTURE)
                                                }}
                                            >
                                                Late Departure
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            
                        {/* </div> */}
                    </div>
                </div>
            </section >
            </div>

        </>
    );
};

export default FlightSort;
