import img1 from '../../../assets/img/home/flight.svg'
import img2 from '../../../assets/img/home/user_friendly.svg'
import img3 from '../../../assets/img/home/routes.svg'





export const ReviewData = [
    {
        img: img1,
        date:"26 Oct 2021",
        status:"Excellent",
        name:"Flight Performance Data", 
        des:"Tourist",
        review:`Access reliable, historical data on flight punctuality to choose the most on-time flights and avoid delays."`
    },
    {
        img: img2,
        date:"08 Dec 2021",
        status:"Excellent",
        name:"User Friendly Interface", 
        des:"Traveler",
        review:`Navigate effortlessly through our platform with a clean, intuitive design that makes finding the right flight quick and easy.`
    },
    {
        img: img3,
        date:"26 Oct 2021",
        status:"Excellent",
        name:"Route Specific Insights", 
        des:"Manager",
        review:`Get tailored insights for specific routes, helping you understand which flights consistently perform best between your chosen destinations.`
    },
]