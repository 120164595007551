import React, { useState } from "react";

const SideBar = (props) => {

  const { allowedAirlines, setAllowedAirlines } = props;

  const airlineAliases = Object.freeze({
    AIR_INDIA_EXPRESS: ["AIR_INDIA_EXPRESS_CONNECT"]
  });

  const handleCheckboxChange = (event) => {

    const value = event.target.value;
    if (event.target.checked) {
      let tempAllowedAirlines = [...allowedAirlines, value];
      if (airlineAliases[value]) {
        let aliases = airlineAliases[value]
        for (let i = 0; i < aliases.length; i++) {
          tempAllowedAirlines = [...tempAllowedAirlines, aliases[i]];
        }
      }
      setAllowedAirlines(tempAllowedAirlines);
    } else {
      let tempAllowedAirlines = allowedAirlines.filter((val) => val !== value);
      if (airlineAliases[value]) {
        let aliases = airlineAliases[value]
        for (let i = 0; i < aliases.length; i++) {
          tempAllowedAirlines = tempAllowedAirlines.filter((val) => val !== aliases[i]);
        }
      }
      setAllowedAirlines(tempAllowedAirlines);
    }
  };

  return (
    <>
      <div class="desktop-only">
        <div className="left_side_search_area">
          {/* <div className="left_side_search_boxed">
          <div className="left_side_search_heading">
            <h5>Filter by price</h5>
          </div>
          <div className="filter-price">
            <div id="price-slider"></div>
          </div>
          <button className="apply" type="button">
            Apply
          </button>
          <div className="left_side_search_heading">
            <h5>Sort by</h5>
          </div>
          <div className="filter-price">
            <div id="price-slider"></div>
          </div>
          <div className="tour_search_type">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="1"
                id="flexCheckDefaultf1"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultf1">
                <span className="area_flex_one">
                  <span>1 stop</span>
                  <span>20</span>
                </span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="2"
                id="flexCheckDefaultf2"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultf2">
                <span className="area_flex_one">
                  <span>2 stop</span>
                  <span>16</span>
                </span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="3"
                id="flexCheckDefaultf3"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultf3">
                <span className="area_flex_one">
                  <span>3 stop</span>
                  <span>30</span>
                </span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="4"
                id="flexCheckDefaultf4"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultf4">
                <span className="area_flex_one">
                  <span>Non-stop</span>
                  <span>22</span>
                </span>
              </label>
            </div>
          </div>
          <button className="apply" type="button">
            Apply
          </button>
        </div>
        <div className="left_side_search_boxed">
          <div className="left_side_search_heading">
            <h5>Number of stops</h5>
          </div>
          <div className="tour_search_type">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="1"
                id="flexCheckDefaultf1"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultf1">
                <span className="area_flex_one">
                  <span>1 stop</span>
                  <span>20</span>
                </span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="2"
                id="flexCheckDefaultf2"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultf2">
                <span className="area_flex_one">
                  <span>2 stop</span>
                  <span>16</span>
                </span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="3"
                id="flexCheckDefaultf3"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultf3">
                <span className="area_flex_one">
                  <span>3 stop</span>
                  <span>30</span>
                </span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="4"
                id="flexCheckDefaultf4"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultf4">
                <span className="area_flex_one">
                  <span>Non-stop</span>
                  <span>22</span>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="left_side_search_boxed">
          <div className="left_side_search_heading">
            <h5>Flight className</h5>
          </div>
          <div className="tour_search_type">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="5"
                id="flexCheckDefaultt1"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultt1">
                <span className="area_flex_one">
                  <span>Economy</span>
                  <span>20</span>
                </span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="6"
                id="flexCheckDefaultt2"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultt2">
                <span className="area_flex_one">
                  <span>Business</span>
                  <span>26</span>
                </span>
              </label>
            </div>
          </div>
        </div> */}
          <div className="left_side_search_boxed">
            <div className="left_side_search_heading">
              <h5>Airlines</h5>
            </div>
            <div className="tour_search_type">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue="AIR_INDIA"
                  id="flexCheckDefaults1"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefaults1">
                  <span className="area_flex_one">
                    <span>Air India</span>
                    {/* <span>17</span> */}
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue="INDIGO"
                  id="flexCheckDefaults2"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefaults2">
                  <span className="area_flex_one">
                    <span>Indigo</span>
                    {/* <span>14</span> */}
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue="VISTARA"
                  id="flexCheckDefaults3"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefaults3">
                  <span className="area_flex_one">
                    <span>Vistara</span>
                    {/* <span>62</span> */}
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue="SPICEJET"
                  id="flexCheckDefaults4"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefaults4">
                  <span className="area_flex_one">
                    <span>Spicejet</span>
                    {/* <span>08</span> */}
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue="AKASA_AIR"
                  id="flexCheckDefaults5"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefaults5">
                  <span className="area_flex_one">
                    <span>Akasa Air</span>
                    {/* <span>12</span> */}
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue="AIR_INDIA_EXPRESS"
                  id="flexCheckDefaults6"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefaults6">
                  <span className="area_flex_one">
                    <span>Air India Express</span>
                    {/* <span>17</span> */}
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue="ALLIANCE_AIR"
                  id="flexCheckDefaults7"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefaults7">
                  <span className="area_flex_one">
                    <span>Alliance Air</span>
                    {/* <span>17</span> */}
                  </span>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue="STAR_AIR"
                  id="flexCheckDefaults8"
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefaults8">
                  <span className="area_flex_one">
                    <span>Star Air</span>
                    {/* <span>17</span> */}
                  </span>
                </label>
              </div>
            </div>
          </div>
          {/* <div className="left_side_search_boxed">
          <div className="left_side_search_heading">
            <h5>Refundable</h5>
          </div>
          <div className="tour_search_type">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="12"
                id="flexCheckDefaultp1"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultp1">
                <span className="area_flex_one">
                  <span>Yes</span>
                  <span>17</span>
                </span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="13"
                id="flexCheckDefaultp2"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultp2">
                <span className="area_flex_one">
                  <span>No</span>
                  <span>14</span>
                </span>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultValue="14"
                id="flexCheckDefaultp3"
              />
              <label className="form-check-label" htmlFor="flexCheckDefaultp3">
                <span className="area_flex_one">
                  <span>As per rules</span>
                  <span>62</span>
                </span>
              </label>
            </div>
          </div>
        </div> */}
        </div>
      </div>
      <div class="mobile-only">
        <div className="left_side_search_area">
          <div className="left_side_search_boxed">
            <div className="left_side_search_heading">
              <h5>Airlines</h5>
            </div>
            <div className="tour_search_type">
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue="AIR_INDIA"
                      id="AIR_INDIA"
                      onChange={handleCheckboxChange}
                    />
                    <label className="no-pointer-events" htmlFor="AIR_INDIA">
                      <span className="area_flex_one">
                        <span>Air India</span>
                        {/* <span>17</span> */}
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue="INDIGO"
                      id="INDIGO"
                      onChange={handleCheckboxChange}
                    />
                    <label className="no-pointer-events" htmlFor="INDIGO">
                      <span className="area_flex_one">
                        <span>Indigo</span>
                        {/* <span>14</span> */}
                      </span>
                    </label>
                  </div>
                  </div>
                <div className="col-sm-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue="VISTARA"
                      id="VISTARA"
                      onChange={handleCheckboxChange}
                    />
                    <label className="no-pointer-events" htmlFor="VISTARA">
                      <span className="area_flex_one">
                        <span>Vistara</span>
                        {/* <span>62</span> */}
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue="SPICEJET"
                      id="SPICEJET"
                      onChange={handleCheckboxChange}
                    />
                    <label className="no-pointer-events" htmlFor="SPICEJET">
                      <span className="area_flex_one">
                        <span>Spicejet</span>
                        {/* <span>08</span> */}
                      </span>
                    </label>
                  </div>
                  </div>
                <div className="col-sm-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue="AKASA_AIR"
                      id="AKASA_AIR"
                      onChange={handleCheckboxChange}
                    />
                    <label className="no-pointer-events" htmlFor="AKASA_AIR">
                      <span className="area_flex_one">
                        <span>Akasa Air</span>
                        {/* <span>12</span> */}
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue="AIR_INDIA_EXPRESS"
                      id="AIR_INDIA_EXPRESS"
                      onChange={handleCheckboxChange}
                    />
                    <label className="no-pointer-events" htmlFor="AIR_INDIA_EXPRESS">
                      <span className="area_flex_one">
                        <span>Air India Exp</span>
                        {/* <span>17</span> */}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue="ALLIANCE_AIR"
                      id="ALLIANCE_AIR"
                      onChange={handleCheckboxChange}
                    />
                    <label className="no-pointer-events" htmlFor="ALLIANCE_AIR">
                      <span className="area_flex_one">
                        <span>Alliance Air</span>
                        {/* <span>17</span> */}
                      </span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue="STAR_AIR"
                      id="STAR_AIR"
                      onChange={handleCheckboxChange}
                    />
                    <label className="no-pointer-events" htmlFor="STAR_AIR">
                      <span className="area_flex_one">
                        <span>Star Air</span>
                        {/* <span>17</span> */}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
