import img1 from '../../../assets/img/icon/world.png'
import img2 from '../../../assets/img/icon/walte.png'
import img3 from '../../../assets/img/icon/star.png'
import img4 from '../../../assets/img/icon/persentis.png'





export const ServiceData = [
    {
        img: img1,
        heading: "Best services",
        para:`Phaseus site amet tristique ligua donec iaculis leo sus cipit. Consec tetur adipiscing elit.
        Incididunt ut dolore.`
    },
   
    {
        img: img2,
        heading: "Trusted payment",
        para:`Phaseus site amet tristique ligua donec iaculis leo sus cipit. Consec tetur adipiscing elit.
        Incididunt ut dolore.`
    },
   
    {
        img: img3,
        heading: "Top facility",
        para:`Phaseus site amet tristique ligua donec iaculis leo sus cipit. Consec tetur adipiscing elit.
        Incididunt ut dolore.`
    },
   
    {
        img: img4,
        heading: "Awesome deals",
        para:`Phaseus site amet tristique ligua donec iaculis leo sus cipit. Consec tetur adipiscing elit.
        Incididunt ut dolore.`
    },
   
]