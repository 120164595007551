import React, { useEffect, useState } from 'react'
// import  CommonBanner
import CommonBanner from '../component/Common/CommonBanner'
// import FlightSearchArea
import FlightSearchArea from '../component/FlightSearch'
import axios from 'axios';
import Loader from './Loader';
import Banner from '../component/Home/Banner';

const FlightSearch = () => {

  const [flightData, setFlightData] = useState([]);
  const [statsData, setStatsData] = useState([]);

  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  // useEffect(() => {
  //   document.body.style.zoom = "75%";
  // });

  useEffect(() => {
    // Function to update zoom based on viewport width
    const updateZoom = () => {
      if (window.innerWidth > 900) {
        document.body.style.zoom = "75%";
      } else {
        document.body.style.zoom = "100%";
      }
    };

    // Initial check
    updateZoom();

    // Add event listener for window resize
    window.addEventListener('resize', updateZoom);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateZoom);
    };
  }, []);

  // useEffect(() => {
  //   // Function to increase font size
  //   const increaseFontSizeForPhones = () => {
  //     // Define the pixel increase
  //     const increaseBy = 3; // Increase font size by 5px

  //     // Check if viewport width is less than or equal to 900px (adjust as needed for phone viewports)
  //     if (window.innerWidth <= 900) {
  //       // Get all elements
  //       const elements = document.querySelectorAll('*');

  //       elements.forEach(element => {
  //         // Get the current font size
  //         const computedStyle = getComputedStyle(element);
  //         const currentFontSize = parseFloat(computedStyle.fontSize);

  //         // Increase the font size
  //         const newFontSize = currentFontSize + increaseBy;

  //         // Set the new font size
  //         element.style.fontSize = `${newFontSize}px`;
  //       });
  //     } else {
  //       // Optionally reset font sizes if the viewport is greater than 900px
  //       const elements = document.querySelectorAll('*');
  //       elements.forEach(element => {
  //         element.style.fontSize = ''; // Reset to default font size
  //       });
  //     }
  //   };

  //   // Initial call to adjust font size
  //   increaseFontSizeForPhones();

  //   // Add resize event listener
  //   window.addEventListener('resize', increaseFontSizeForPhones);

  //   // Cleanup function to remove event listener
  //   return () => {
  //     window.removeEventListener('resize', increaseFontSizeForPhones);
  //   };
  // }, []);

  useEffect(() => {
    console.log("API CALLED 1!!!!")
    // axios.get('http://localhost:8080/api/flights')
    axios.get('https://safarontime-springbootapp-new.an.r.appspot.com/api/flights')
      .then(response => {
        setFlightData(response.data);
        setLoading1(false);
      })
      .catch(error => {
        setLoading1(false);
      });

  }, []);

  const x = {
    flightNumber: "6E-116"
  }

  useEffect(() => {
    console.log("API CALLED 2!!")
    // axios.get('http://localhost:8080/api/flightstats')
    axios.get('https://safarontime-springbootapp-new.an.r.appspot.com/api/flightstats')
      .then(response => {
        setStatsData(response.data);
        setLoading2(false);
      })
      .catch(error => {
        // console.log("ERROR!!!!")
        setLoading2(false);
      });

  }, []);


  // const statsData2 = {
  //   "6E-638": {
  //     totalFlights: 21,
  //     totalDelays: 4,
  //     averageDelay: 14,
  //     standardDeviation: 7.1,
  //     minDelay: 0,
  //     maxDelay: 23,
  //     chart: {
  //       on_time: 30,
  //       late: 10,
  //       very_late: 5,
  //       excessive: 5,
  //       cancelled: 0,
  //     }
  //   },
  //   "6E-116": {
  //     totalFlights: 21,
  //     totalDelays: 4,
  //     averageDelay: 14,
  //     standardDeviation: 7.1,
  //     minDelay: 0,
  //     maxDelay: 23,
  //     chart: {
  //       on_time: 100,
  //       late: 10,
  //       very_late: 5,
  //       excessive: 5,
  //       cancelled: 0,
  //     }
  //   }
  // }

  const shallowCopyFlightData = [...flightData];
  const shallowCopyStatsData = { ...statsData };


  return (
    <div className='scale-my-app'>
      {/* <CommonBanner heading="Flight search result" pagination="Flight search result"/> */}
      {!(loading1 || loading2) ?
        <>
          {/* <CommonBanner /> */}
          <Banner />
          <div className='scale-my-app-max-width'>
            <FlightSearchArea flightData={shallowCopyFlightData} statsData={shallowCopyStatsData} />
          </div>
        </>
        :
        <Loader />
      }
      {/* <Loader /> */}
    </div>
  )
}

export default FlightSearch