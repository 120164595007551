import React, { useState } from 'react'
// imoprt Email
import Email from '../assets/img/icon/mail_box.png'

const CtaArea = () => {

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);

    const validateEmail = (email) => {
        if (email.trim() == "") {
            return false
        }
        // Basic email regex pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleSubmit = () => {

        if (!validateEmail(email)) {
            setEmailError(true);
            return
        } else {
            setEmailError(false);
        }

        const contactForm = {
            firstName: "subscriber", // Set firstName to "subscriber"
            lastName: "",            // Set lastName to empty string
            email,                   // Keep the provided email
            mobile: "",              // Set mobile to empty string
            msg: "add new subscriber"                  // Set msg to empty string
        };

        // Send the POST request and do not wait for the response
        fetch('https://safarontime-springbootapp-new.an.r.appspot.com/api/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(contactForm),
        }).catch(error => {
            console.error('Error:', error);
            // Handle the error if needed
        });

        alert("Thank you for subscribing to our newsletter!")

        setEmail('')
    }

    return (
        <>
            <section id="cta_area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="cta_left">
                                <div className="cta_icon">
                                    <img src={Email} alt="icon" />
                                </div>
                                <div className="cta_content">
                                    <h4>Get the latest news and offers</h4>
                                    <h2>Subscribe to our newsletter</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="cat_form">
                                <form id="cta_form_wrappper">
                                    <div className="input-group">
                                        <input
                                            type="email"
                                            // className="form-control"
                                            className={emailError ? "form-control bg_input error" : "form-control bg_input"}
                                            placeholder="Enter your mail address"
                                            value={email}
                                            onChange={(event) => {
                                                setEmail(event.target.value)
                                            }}
                                        />

                                        <button className="btn btn_theme btn_md"
                                            type="button"
                                            onClick={handleSubmit}
                                        >Subscribe
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CtaArea
