import React from 'react'
// Import Link
import { Link } from 'react-router-dom'
// import Img
import Img from '../../../assets/img/home/profile.JPG'
import SectionHeading from '../../Common/SectionHeading'



const AboutArea = () => {
    return (
        <>
            <section id="go_beyond_area" className="section_padding_top">
                <div className="container">
                    <br></br>
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h2><span>Our Mission</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h4>At Safar On Time, our mission is to empower travelers with the knowledge they need to make informed decisions about their flights. We believe that providing transparent flight performance data can lead to better travel experiences and help travelers plan with confidence.
                                </h4>
                            </div>
                        </div>
                        {/* {DiscoverData.map((data, index) => (
                     <DiscoverCard data={data} key={index} /> 
                ))} */}

                    </div>
                    <br></br>
                    <br></br>
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h2><span>Our Story</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h4>The idea for Safar On Time was born out of personal experiences with frequent flight delays. After numerous instances of choosing flights that ended up being delayed more than others, we realized the need for a tool that provides reliable, historical flight performance data. With access to this data, travelers can choose the best flights, be better prepared, and enjoy smoother journeys.</h4>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h2><span>What We Do</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h4>We provide a comprehensive database of historical flight performance data for routes across India. By analyzing trends in flight delays, we help travelers choose the most reliable flights and avoid unnecessary disruptions. Our platform is designed to be user-friendly, offering detailed insights, and suggesting the most reliable flights.
                                </h4>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h2><span>Why It Matters</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h4>Making travel decisions can be challenging, especially when it comes to selecting flights. With Safar On Time, we aim to make this process easier and more informed. By providing easy access to past performance data, we empower you to select flights with confidence, knowing you’re making the best choice for your journey.</h4>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h2><span>Join Us On Our Journey</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h4>We’re just getting started, and we’d love for you to join us on this journey. Whether you’re a frequent flyer, an occasional traveler, or someone who values a smooth travel experience, Safar On Time is here to help. Join our newsletter for monthly data insights, best airline performance and best airports to travel from.</h4>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    {/* <div className="row align-items-center">
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h2><span>Meet The Team</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h4>Keerthan Photo - Hi, I’m Keerthan, founder of Safar On Time. Frustrated by frequent flight delays, I created this platform to help travelers make informed decisions with reliable flight performance data. My goal is to make travel planning smoother and more predictable. When I’m not working, you can find me playing football, hiking, riding, or gaming. Thanks for joining me on this journey!</h4>
                            </div>
                        </div>
                    </div> */}
                    {/* <br></br> */}
                    {/* <br></br> */}
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h2><span>Get In Touch</span></h2>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h4>We’re always looking for feedback and suggestions to improve our platform. If you have any ideas or just want to say hi, feel free to reach out. We’re excited to hear from you! Check out our Contact page for more information.</h4>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    {/* <div className="row align-items-center">
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h2><span>Meet Our Founder</span></h2>
                            </div>
                        </div> */}
                    {/* <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h4>We’re always looking for feedback and suggestions to improve our platform. If you have any ideas or just want to say hi, feel free to reach out. We’re excited to hear from you! Check out our Contact page for more information.</h4>
                            </div>
                        </div> */}
                    {/* </div> */}
                    {/* <div className="row align-items-center">
                        <div className="col-lg-2">
                            <div className="about_us_right">
                                <img src={Img} alt="img" />
                                <h2>Keerthan Ramesh</h2>
                                <h2>IIT Bombay'22</h2>
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                            <div className="heading_left_area custom_aboutus">
                                <h4>Hi, I’m Keerthan, founder of Safar On Time. Frustrated by frequent flight delays, I created this platform to help travelers make informed decisions with reliable flight performance data. My goal is to make travel planning smoother and more predictable. When I’m not working, you can find me playing football, hiking, riding, or gaming. Thanks for joining me on this journey!
                                </h4>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section >
            <div class="desktop-only">
                <section id="about_us_top" className="section_padding">
                    <div className="container">
                        <SectionHeading heading="Meet Our Founder" />
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="about_us_left">
                                    {/* <h5>About us</h5> */}
                                    <h3>"Safar, Don't Suffer!"</h3>
                                    <p>Hi, I’m Keerthan, founder of Safar On Time.
                                        Frustrated by frequent flight delays, I created this platform to help
                                        travelers make informed decisions with reliable flight performance data.
                                        My goal is to make travel planning smoother and more predictable.
                                        When I’m not working, you can find me playing football, hiking, riding, or gaming.</p>
                                    <p> Thanks for joining me on this journey!</p>
                                    {/* <Link to="" className="btn btn_theme btn_md">Find tours</Link> */}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="about_us_right">
                                    <img src={Img} alt="img" />
                                    <h4>Keerthan Ramesh</h4>
                                    <h4>IIT Bombay'22</h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>

            <div class="mobile-only">
                <section id="about_us_top" className="section_padding">
                    <div className="container">
                        <SectionHeading heading="Meet Our Founder" />
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="about_us_left">
                                    {/* <h5>About us</h5> */}
                                    <h3>"Safar, Don't Suffer!"</h3>
                                    <p>Hi, I’m Keerthan, founder of Safar On Time.
                                        Frustrated by frequent flight delays, I created this platform to help
                                        travelers make informed decisions with reliable flight performance data.
                                        My goal is to make travel planning smoother and more predictable.
                                        When I’m not working, you can find me playing football, hiking, riding, or gaming.</p>
                                    <p> Thanks for joining me on this journey!</p>
                                    {/* <Link to="" className="btn btn_theme btn_md">Find tours</Link> */}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="about_us_right">
                                    <img src={Img} alt="img" />
                                    <h4>Keerthan Ramesh</h4>
                                    <h4>IIT Bombay'22</h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default AboutArea