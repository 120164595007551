import React, { useState, useEffect } from "react";
// import SectionHeading
import SectionHeading from "../Common/SectionHeading";
import FlightsFound from "../Common/FlightsFound";
// import Sidebar
import SideBar from "./SideBar";
// import Flight Data
import { FlightData } from "./FlightData";
// import Link
import { Link } from "react-router-dom";
import SortCriterion from "./index";
import OnTimePerformanceChart from "./OnTimePerformanceChart";
import DelayStatistics from "./DelayStatistics";
import flightIcon from '../../assets/img/icon/right_arrow.png'
import arrowIcon2 from '../../assets/img/icon/right_arrow_blue.png'
import arrowIcon from '../../assets/img/icon/right_arrow_blue2.png'

import INDIGO from '../../assets/img/icon/indigo.svg'
import AKASA_AIR from '../../assets/img/icon/akasa.svg'
import SPICEJET from '../../assets/img/icon/spicejet.svg'
import AIR_INDIA from '../../assets/img/icon/airindia.svg'
import VISTARA from '../../assets/img/icon/vistara.svg'
import AIR_INDIA_EXPRESS from '../../assets/img/icon/airindiaexpress.svg'
import ALLIANCE_AIR from '../../assets/img/icon/allianceair.svg'
import STAR_AIR from '../../assets/img/icon/starair.svg'



const Airlines = Object.freeze({
  AIR_INDIA: "Air India",
  INDIGO: "Indigo",
  VISTARA: "Vistara",
  SPICEJET: "Spicejet",
  AKASA_AIR: "Akasa Air",
  AIR_INDIA_EXPRESS: "Air India Express",
  AIR_INDIA_EXPRESS_CONNECT: "Air India Express",
  ALLIANCE_AIR: "Alliance Air",
  STAR_AIR: "Star Air"
});

const AirlinesToIcon = Object.freeze({
  AIR_INDIA: AIR_INDIA,
  INDIGO: INDIGO,
  VISTARA: VISTARA,
  SPICEJET: SPICEJET,
  AKASA_AIR: AKASA_AIR,
  AIR_INDIA_EXPRESS: AIR_INDIA_EXPRESS,
  AIR_INDIA_EXPRESS_CONNECT: AIR_INDIA_EXPRESS,
  ALLIANCE_AIR: ALLIANCE_AIR,
  STAR_AIR: STAR_AIR
});


const FlightSearchWrapper = (props) => {

  const { filteredFlightData, statsData } = props

  const [filteredFlightDataIncAirline, setFilteredFlightDataIncAirline] = useState([...filteredFlightData]);

  const [allowedAirlines, setAllowedAirlines] = useState([])

  useEffect(() => {
    // console.log("MY STATS DATA: ", statsData)
    if (allowedAirlines.length > 0) {
      const data_new = filteredFlightData.filter((data) =>
        allowedAirlines.includes(data.airline)
      )
      setFilteredFlightDataIncAirline(data_new);
    }
    else {
      setFilteredFlightDataIncAirline(filteredFlightData);
    }
  }, [allowedAirlines, filteredFlightData]);

  // Function to get OTP for a given flight number
  function getOtpForFlight(flightNumber) {
    // Access the flight data using the flight number as the key
    const flight = statsData[flightNumber];
    if (flight) {
      return flight.otp;
    } else {
      return "NA";
    }
  }

  return (
    <>
      {/* <!-- Desktop-only content --> */}
      <div class="desktop-only">
        <section id="explore_area" className="section_padding_half">
          <div className="container">
            {filteredFlightDataIncAirline.length > 0 ?
              <FlightsFound heading={filteredFlightDataIncAirline.length + " flights found"} />
              :
              <FlightsFound heading={"Oops! No flights found"} />
            }

            <br></br>
            <div className="row">
              <div className="col-lg-3">
                <SideBar allowedAirlines={allowedAirlines} setAllowedAirlines={setAllowedAirlines} />
              </div>
              <div className="col-lg-9">
                <div className="row">
                  {/* <div className="col-lg-12"> */}
                  <div className="flight_search_result_wrapper">
                    {filteredFlightDataIncAirline
                      .map((data, index) => (
                        <div className="flight_search_item_wrappper" key={index}>
                          <div className="flight_search_items">
                            <div className="multi_city_flight_lists">
                              <div className="flight_multis_area_wrapper">
                                <div className="flight_search_left">
                                  <div className="flight_logo">
                                    <div className="flight_search_destination">
                                      {/* <img src="../../assets/img/banner/bg1.png" /> */}
                                      <img src={AirlinesToIcon[data.airline]} alt="icon" style={{ "width": "50px" }} />
                                      <h6>{Airlines[data.airline]}</h6>
                                      <h4>{data.flightNumber.split('_')[0]}</h4>
                                    </div>
                                  </div>
                                  <div className="flight_search_destination">
                                    <p>From</p>
                                    {/* <h3>{data.departureDate.substring(11, 16)}</h3> */}
                                    <h3>{data.departureTime.substring(0, 5)}</h3>
                                    <h5>{data.from}</h5>
                                  </div>
                                </div>
                                <div className="flight_search_middel">
                                  <div className="flight_right_arrow">
                                    <img src={arrowIcon} alt="icon" />
                                    <h6>Non-stop</h6>
                                    <p>{data.duration} </p>
                                  </div>
                                  <div className="flight_search_destination">
                                    <p>To</p>
                                    {/* <h3>{data.arrivalDate.substring(11, 16)}</h3> */}
                                    <h3>{data.arrivalTime.substring(0, 5)}</h3>
                                    <h5>{data.to}</h5>
                                  </div>
                                </div>
                                {/* <div className="flight_search_right">
                                <div className={getOtpForFlight(data.flightNumber) >= 80 ? "flight_otp_green" : getOtpForFlight(data.flightNumber) < 50 ? "flight_otp_red" : "flight_otp_black"}>
                                  <h4>
                                    {getOtpForFlight(data.flightNumber) == "NA" ? "-" : getOtpForFlight(data.flightNumber) + "%"}
                                  </h4>
                                </div>
                                <h5>
                                  On Time Performance
                                </h5>
                                <br></br>
                                <div
                                  className="btn btn_theme btn_sm"
                                  data-bs-toggle="collapse"
                                  data-bs-target={"#collapseExample" + index}
                                  aria-expanded="false"
                                  aria-controls={"collapseExample" + index}
                                >
                                  More details <i className="fas fa-chevron-down"></i>
                                </div>
                              </div> */}
                              </div>
                            </div>
                            <div className="flight_search_right">
                              <div className={getOtpForFlight(data.flightNumber) >= 80 ? "flight_otp_green" : getOtpForFlight(data.flightNumber) < 50 ? "flight_otp_red" : "flight_otp_black"}>

                                <h4>
                                  {getOtpForFlight(data.flightNumber) == "NA" ? "-" : getOtpForFlight(data.flightNumber) + "%"}
                                </h4>
                              </div>
                              <h5>
                                On Time Performance
                              </h5>

                              <br></br>
                              <div
                                className="btn btn_theme btn_sm"
                                data-bs-toggle="collapse"
                                data-bs-target={"#collapseExample" + index}
                                aria-expanded="false"
                                aria-controls={"collapseExample" + index}
                              >
                                More details <i className="fas fa-chevron-down"></i>
                              </div>
                            </div>
                          </div>
                          <div
                            className="flight_policy_refund collapse"
                            id={"collapseExample" + index}
                          >
                            {/* <div className="flight_show_down_wrapper">
                              <div className="flight-shoe_dow_item">
                                <div className="airline-details">
                                  <div className="img">
                                    <img src={data.flightIcon2} alt="img" />
                                  </div>
                                  <span className="airlineName fw-500">
                                    Biman Bangladesh Airlines &nbsp; BG435
                                  </span>
                                  <span className="flightNumber">
                                    BOEING 737-800 - 738
                                  </span>
                                </div>
                                <div className="flight_inner_show_component">
                                  <div className="flight_det_wrapper">
                                    <div className="flight_det">
                                      <div className="code_time">
                                        <span className="code">DAC</span>
                                        <span className="time">15:00</span>
                                      </div>
                                      <p className="airport">
                                        Hazrat Shahjalal International Airport
                                      </p>
                                      <p className="date">7th Jun 2022</p>
                                    </div>
                                  </div>
                                  <div className="flight_duration">
                                    <div className="arrow_right"></div>
                                    <span>01h 15m</span>
                                  </div>
                                  <div className="flight_det_wrapper">
                                    <div className="flight_det">
                                      <div className="code_time">
                                        <span className="code">DAC</span>
                                        <span className="time">15:00</span>
                                      </div>
                                      <p className="airport">
                                        Hazrat Shahjalal International Airport
                                      </p>
                                      <p className="date">7th Jun 2022</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flight_refund_policy">
                                <div className="TabPanelInner flex_widht_less">
                                  <h4>Refund Policy</h4>
                                  <p className="fz12">
                                    1. Refund and Date Change are done as per the
                                    following policies.
                                  </p>
                                  <p className="fz12">
                                    2. Refund Amount= Refund Charge (as per
                                    airline policy + ShareTrip Convenience Fee).{" "}
                                  </p>
                                  <p className="fz12">
                                    3. Date Change Amount= Date Change Fee (as per
                                    Airline Policy + ShareTrip Convenience Fee).
                                  </p>
                                </div>
                                <div className="TabPanelInner">
                                  <h4>Baggage</h4>
                                  <div className="flight_info_taable">
                                    <h3>DAC-SPD</h3>
                                    <p>
                                      <span>20KG /</span> person
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            {
                              Object.entries(statsData).filter(([flightNumber, stats]) => flightNumber == data.flightNumber).map(([flightNumber, stats]) => (
                                <div className="flight_show_down_wrapper_bar_chart">
                                  <div className="flight-shoe_dow_item">
                                    <OnTimePerformanceChart chartData={stats.chart} x_limit={stats.totalFlights} />
                                  </div>
                                  
                                  <div className="flight_refund_policy">
                                    <div className="TabPanelInner">
                                      <DelayStatistics stats={stats} />
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                  {/* {filteredFlightDataIncAirline.length == 0 &&
                    <div className="load_more_flight">
                      <button className="btn btn_md">
                        Oops! No flights found
                      </button>
                    </div>
                  } */}
                  {/* <div className="load_more_flight">
                    <button className="btn btn_md">
                      <i className="fas fa-spinner"></i> Load more..
                    </button>
                  </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </section >
      </div>
      <div class="mobile-only">
        <section id="explore_area">
          <div className="container">
        

            <br></br>

            <SideBar allowedAirlines={allowedAirlines} setAllowedAirlines={setAllowedAirlines} />
            <br></br>
            {filteredFlightDataIncAirline.length > 0 ?
              <FlightsFound heading={filteredFlightDataIncAirline.length + " flights found"} />
              :
              <FlightsFound heading={"Oops! No flights found"} />
            }

            
            <div className="row">
              <div className="flight_search_result_wrapper">
                {filteredFlightDataIncAirline
                  .map((data, index) => (<>
                    <div className="container mobile-view">
                      <div className="flight_search_item_wrappper" key={index}>
                        <div className="flight_search_items">
                          <div className="multi_city_flight_lists">
                            <div className="flight_multis_area_wrapper">
                              <div className="row">
                                <div className="col-md-3 col-sm-3 col-3">
                                  <div className="flight_logo">
                                    <div className="flight_search_destination">
                                      {/* <img src="../../assets/img/banner/bg1.png" /> */}
                                      <img src={AirlinesToIcon[data.airline]} alt="icon" style={{ "width": "50px" }} />
                                      <h6>{Airlines[data.airline]}</h6>
                                      <h4>{data.flightNumber.split('_')[0]}</h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-2">
                                  <div className="flight_logo">
                                    <div className="flight_search_destination">
                                      <p>From</p>
                                      {/* <h3>{data.departureDate.substring(11, 16)}</h3> */}
                                      <h3>{data.departureTime.substring(0, 5)}</h3>
                                      <h5>{data.from}</h5>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-2">
                                  <div className="flight_right_arrow">
                                    <img src={arrowIcon} alt="icon" />
                                    <h6>Non-stop</h6>
                                    <p>{data.duration.slice(0, -4)} </p>
                                  </div>
                                </div>
                                <div className="col-md-2 col-sm-2 col-2">
                                  <div className="flight_search_destination">
                                    <p>To</p>
                                    {/* <h3>{data.arrivalDate.substring(11, 16)}</h3> */}
                                    <h3>{data.arrivalTime.substring(0, 5)}</h3>
                                    <h5>{data.to}</h5>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-3 col-3">
                                  <div className="text_align_center mobile_view_otp">
                                    <div className={getOtpForFlight(data.flightNumber) >= 80 ? "flight_otp_green" : getOtpForFlight(data.flightNumber) < 50 ? "flight_otp_red" : "flight_otp_black"}>

                                      <h4>
                                        {getOtpForFlight(data.flightNumber) == "NA" ? "-" : getOtpForFlight(data.flightNumber) + "%"}
                                      </h4>
                                    </div>
                                    <h5>
                                      On Time Performance
                                    </h5>

                                    {/* <br></br> */}
                                    <div
                                      className="btn btn_theme btn_sm"
                                      data-bs-toggle="collapse"
                                      data-bs-target={"#collapseExample" + index}
                                      aria-expanded="false"
                                      aria-controls={"collapseExample" + index}
                                    >
                                      More details <i className="fas fa-chevron-down"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="flight_policy_refund collapse"
                                id={"collapseExample" + index}
                              >
                                {
                                  Object.entries(statsData).filter(([flightNumber, stats]) => flightNumber == data.flightNumber).map(([flightNumber, stats]) => (
                                    <div className="flight_show_down_wrapper_bar_chart">
                                      <div className="flight-shoe_dow_item">
                                        <OnTimePerformanceChart chartData={stats.chart} x_limit={stats.totalFlights} />
                                      </div>
                                      <div className="flight_refund_policy">
                                        <div className="TabPanelInner">
                                          <DelayStatistics stats={stats} />
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>))}
              </div>
            </div>
          </div >
        </section >
      </div >
    </>
  );
};

export default FlightSearchWrapper;
