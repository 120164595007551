import React, { useState, useEffect } from 'react'
import axios from 'axios';
// import FlightForm 
import FlightForm from './FlightForm'
// import FlightSearchWrapper 
import FlightSearchWrapper from './FlightSearchArea'
import { FlightData } from './FlightData';
import FlightSort from './FlightSort';
import AboutUs from '../Home/AboutUs'
import TopDestinations from '../Home/TopDestinations'
import ExploreArea from '../Home/Explore'
import SpecialOffer from '../Home/SpecialOffers'
import PromotionalTours from '../Home/PromotionalTours'
import DestinationsArea from '../Home/Destinations'
import BlogMain from '../Home/LatestNews'
import KeyFeatures from '../About/KeyFeatures';

const SortCriterion = Object.freeze({
  PERFORMANCE: 'Performance',
  EARLY_DEPARTURE: 'Early Departure',
  LATE_DEPARTURE: 'Late Departure',
});

const FlightSearchArea = ({ flightData, statsData, initialLandingProp }) => {
  const [filteredFlightData, setFilteredFlightData] = useState([...flightData]);
  const [sortCriterion, setSortCriterion] = useState(SortCriterion.PERFORMANCE)
  const [initialLanding, setInitialLanding] = useState(true);
  const [searchClicked, setSearchClicked] = useState(true);

  const [avgOtpRoute, setAvgOtpRoute] = useState(null);
  const [avgDelayRoute, setAvgDelayRoute] = useState(null);
  const [dataDateRange, setDataDateRange] = useState(null);



  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   axios.get('http://localhost:8080/api/flights')
  //     .then(response => {
  //       setFilteredFlightData(response.data);
  //       console.log("GOT DATA: ", response.data)
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       setError(error.message);
  //       console.log("ERROR!!!!")
  //       setLoading(false);
  //     });

  // }, [initialLanding]);

  useEffect(() => {
    setFilteredFlightData(flightData);
  }, [flightData]);

  // Function to get OTP for a given flight number
  function getOtpForFlight(flightNumber) {
    // Access the flight data using the flight number as the key
    const flight = statsData[flightNumber];
    if (flight) {
      return flight.otp;
    } else {
      return -1;
    }
  }

  const parseTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const now = new Date();
    now.setHours(hours, minutes, seconds, 0); // Set hours, minutes, seconds, and milliseconds
    return now;
  };

  useEffect(() => {
    if (filteredFlightData.length > 0) {
      let flight = filteredFlightData[0]
      let flightStats = statsData[flight.flightNumber]

      if (flightStats) {
        setAvgOtpRoute(flightStats.avgOtpRoute)
        setAvgDelayRoute(flightStats.avgDelayRoute)
        setDataDateRange(flightStats.dataDateRange)

        console.log("MUSKAN BHATT")
        console.log(flightStats.avgOtpRoute)
        console.log(flightStats.avgDelayRoute)
        console.log(flightStats.dataDateRange)
      }
    }
    else {
      setAvgOtpRoute(null)
      setAvgDelayRoute(null)
      setDataDateRange(null)
    }
  }, [filteredFlightData]);

  // Comparator function for sorting
  const compareDepartureTimes = (flight1, flight2) => {
    const time1 = parseTime(flight1.departureTime);
    const time2 = parseTime(flight2.departureTime);

    if (time1 > time2) return 1;
    if (time1 < time2) return -1;
    return 0;
  };

  useEffect(() => {
    let sortedFlightData = [...filteredFlightData];
    switch (sortCriterion) {

      case SortCriterion.PERFORMANCE:
        sortedFlightData.sort((a, b) => getOtpForFlight(b.flightNumber) - getOtpForFlight(a.flightNumber))
        break;

      case SortCriterion.EARLY_DEPARTURE:
        // sortedFlightData.sort((a, b) => new Date(a.departureDate) - new Date(b.departureDate))
        sortedFlightData.sort((a, b) => compareDepartureTimes(a, b))
        break;

      case SortCriterion.LATE_DEPARTURE:
        // sortedFlightData.sort((a, b) => new Date(b.departureDate) - new Date(a.departureDate))
        sortedFlightData.sort((a, b) => compareDepartureTimes(b, a))
        break;
    }

    setFilteredFlightData(sortedFlightData);

  }, [sortCriterion, initialLanding, searchClicked]);

  return (
    <>
      <FlightForm flightData={flightData} setFilteredFlightData={setFilteredFlightData} setInitialLanding={setInitialLanding} searchClicked={searchClicked} setSearchClicked={setSearchClicked} setAvgOtpRoute={setAvgOtpRoute} setAvgDelayRoute={setAvgDelayRoute} setDataDateRange={setDataDateRange} />
      {initialLanding ?
        <>
          <KeyFeatures />
          <AboutUs />
          {/* <TopDestinations />
          <ExploreArea />
          <SpecialOffer />
          <PromotionalTours />
          <DestinationsArea /> */}
          {/* <BlogMain /> */}
        </>
        :
        <>
          <FlightSort setSortCriterion={setSortCriterion} sortCriterion={sortCriterion} avgOtpRoute={avgOtpRoute} avgDelayRoute={avgDelayRoute} dataDateRange={dataDateRange} />
          <FlightSearchWrapper filteredFlightData={filteredFlightData} sortCriterion={sortCriterion} setFilteredFlightData={setFilteredFlightData} statsData={statsData} />
        </>
      }
    </>
  )
  // return (
  //   <>
  //     <FlightForm setFilteredFlightData={setFilteredFlightData} />
  //     <FlightSort setSortCriterion={setSortCriterion} sortCriterion={sortCriterion} />
  //     <FlightSearchWrapper filteredFlightData={filteredFlightData} sortCriterion={sortCriterion} setFilteredFlightData={setFilteredFlightData} />
  //   </>
  // )
}

export default FlightSearchArea