import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const useScrollToPosition = (position) => {
  // useEffect(() => {
    if (position === 'top') {
      console.log("SCROLLING TO TOP")
      window.scrollTo(0, 1);
    } else if (position === 'bottom') {
      if ( window.innerWidth < 1000){
        console.log("SCROLLING TO 1800")
        window.scrollTo(0, 1800);
      }
      else{
        console.log("SCROLLING TO 900")
        window.scrollTo(0, 900);
      }
    }
  // }, [position]);
};

const ScrollToPosition = () => {
  const { pathname } = useLocation();

  // Determine scroll position based on route
  const scrollPosition = pathname === '/team' ? 'bottom' : 'top';
  useScrollToPosition(scrollPosition);

  return null;
};

export default ScrollToPosition;
