import React from "react";

const FlightsFound = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="section_heading_center">
            <h3><b>{props.heading}</b></h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlightsFound;
