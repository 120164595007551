import React, { useEffect } from 'react';

const Loader = () => {

    useEffect(() => {
        console.log("LOADER RENDERD!!!!")
    }, []);

    return (
        <div className="preloader">
            <div className="lds-spinner">
                {/* Create spinner elements */}
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loader;
