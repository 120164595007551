import React from 'react'

const Banner = () => {
  return (
    <>
    <section id="home_one_banner">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-12">
                    <div className="banner_one_text">
                        {/* <h1>Explore the world together</h1> */}
                        <h1><b>Your guide to On Time Flights</b></h1>
                        <br></br>
                        <h3>Building your ultimate tool for finding the most reliable flights, </h3>
                        <h3>ensuring you reach your destination on time, every time!</h3>

                        {/* <h3>Find awesome flights, hotel, tour, car and packages</h3> */}
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Banner